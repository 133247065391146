<template>
    <div class="bg-gray-100 px-6">
        <div class="bg-white rounded-lg shadow-md mb-2 flex lg:flex-row flex-col justify-between items-center">
            <div class="px-12 py-6">
                <h1 
                class="text-blue-700 text-2xl sm:text-3xl 2xl:text-4xl font-normal text-left">
                <faIcon
                icon="address-card"
                type="fas"
                class="text-green-500 text-3xl"
                >
                </faIcon>
                Mi perfil
                </h1>
                <p class="pt-1 text-md sm:text-lg text-gray-600 text-left">Estos son los datos asociados a mi cuenta.</p>
            </div>
        </div>
        <div class="justify-center items-center bg-gray-200 mt-4">
            <div class="p-6 bg-white rounded-md shadow-md">
                <UserProfileForm/>
            </div>
        </div>
    </div>
    
</template>
<script>
import UserProfileForm from "@/components/usuarios/UserProfileForm"
export default {
    name: "ProfileView",
    components: {
        UserProfileForm
    }
}
</script>